import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchDocuments(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/documents', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchDocument(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/documents/${id}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        generateDocument(ctx, { id, payload }) {
            return new Promise((resolve, reject) => {

				const formData = new FormData();

                formData.append("file_screenshot", payload.file_screenshot);
                formData.append("file_template", payload.file_template);
                formData.append("file_template_two", payload.file_template_two);
                formData.append("data", JSON.stringify(payload.data));

                axios
                    .put(`/documents/${id}`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
    }
}